<template>
  <div class="wallet-activity">
    <eden-loader v-if="loading" />
    <template v-else>
      <template v-if="Object.keys(pageData || {}).length">
        <div class="is-flex justify-end">
          <eden-periods
            :default-period="'thisweek'"
            :custom-periods="periods"
            :show-custom-period="true"
            @set-period="getCustomerActivity"
          />
        </div>
        <div
          class="activity-item"
          v-for="(activity, date, index) in pageData"
          :key="index"
        >
          <el-tag class="date">
            {{ date }}
          </el-tag>
          <div class="activities" v-for="(item, idx) in activity" :key="idx">
            <el-row type="flex" align="top">
              <el-col :span="3" class="time">
                <span class="font-sm text-grey-tertiary">
                  {{ item.activity_time }}
                </span>
              </el-col>
              <el-col :span="24" :class="'description'">
                <span class="font-sm">
                  {{ formatText(item.description) }}
                </span>
                <div
                  v-for="(service, key, idx) in item.service_details"
                  :key="idx"
                >
                  <service-activity-card
                    :type="item.service?.toLowerCase()"
                    :order-id="service.customer_order_id"
                    :service="service"
                  />
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </template>
      <eden-content-empty v-else :text="'No activity'" :size="120" />
    </template>
  </div>
</template>

<script>
import ServiceActivityCard from "./ServiceActivityCard.vue";
import wallet from "@/requests/customers/customer/wallet";

export default {
  name: "WalletActivity",
  components: {
    ServiceActivityCard,
  },
  data() {
    return {
      loading: false,
      pageData: {},
      period: "",
      custom: {
        from: null,
        to: null,
      },
      periods: {
        alltime: "All time",
        today: "Today",
        yesterday: "Yesterday",
        thisweek: "This week",
        lastweek: "Last week",
        thismonth: "This month",
      },
    };
  },
  computed: {
    customerId() {
      return this.$route.params.id;
    },
  },
  created() {
    this.getCustomerActivity({ period: "thisweek" });
  },
  methods: {
    getCustomerActivity({ period, from, to }) {
      const { start_date, end_date } = this.formatStartAndEndDate({
        period,
        from,
        to,
      });
      this.loading = true;
      const params = `start_date=${start_date}&end_date=${end_date}`;
      wallet
        .filter(this.customerId, params)
        .then((res) => {
          const { status, data } = res.data;
          if (status) {
            this.pageData = data.reduce(
              (obj, item) => (
                (obj[item.activity_date] = data.filter(
                  (it) => it.activity_date == item.activity_date,
                )),
                obj
              ),
              {},
            );
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.wallet-activity {
  .activity-item {
    margin-bottom: 32px;
    .date {
      margin-bottom: 24px;
    }

    .el-tag {
      background: var(--eden-blue-septenary);
      color: var(--eden-grey-primary);
      font-size: 0.75rem;
      font-weight: 500;
    }

    .activities {
      padding: 0 10px;

      .time {
        padding: 16px 0;
      }

      .description {
        padding: 16px 0;
        border-top: 1px solid #e2e9e6;
      }

      &:last-child {
        .description {
          border-bottom: 1px solid #e2e9e6;
        }
      }
    }
  }
}
</style>
