<template>
  <div>
    <div v-if="loading">Loading</div>
    <template v-else>
      <eden-page-header
        :title="'Customers'"
        :subtitle="customerName"
        :section="'Wallet'"
      >
        <template slot="actions">
          <div class="is-flex is-flex-wrap align-center">
            <customer-actions
              section="wallet"
              :customer="customer"
              @fetch="getCustomer"
              @update="fetchAllItems"
            >
              <template slot="actions-trigger">
                <el-button type="plain">
                  Actions <i class="eden-icon-arrow-down"></i>
                </el-button>
              </template>
            </customer-actions>
          </div>

          <div class="is-flex is-align-center mx-2">
            <el-button
              type="primary"
              v-if="allowAccessFor(['superadmin', 'gardener_pro', 'gardener'])"
              @click="openFundWallet"
            >
              Fund Wallet
            </el-button>
          </div>
        </template>
      </eden-page-header>
      <customers-wallet-banner
        :name="customerName"
        :balance="customer.wallet_balance"
      />
      <div class="mt-4">
        <el-tabs v-model="tab" @tab-click="updateRouteQuery">
          <el-tab-pane label="Wallet Activity" name="activity">
            <wallet-activity ref="walletActivity" />
          </el-tab-pane>
          <el-tab-pane label="Redeemable Services" name="redeemable-services">
            <redeemable-services ref="redeemableServices" />
          </el-tab-pane>
        </el-tabs>
      </div>
      <customer-fund-wallet-option
        :show.sync="showFundWalletOption"
        @update-activity="fetchAllItems"
      />
    </template>
  </div>
</template>

<script>
import CustomersWalletBanner from "@/components/Customers/Individual/Wallet/CustomersWalletBanner.vue";
import WalletActivity from "@/components/Customers/Individual/Wallet/WalletActivity.vue";
import RedeemableServices from "@/components/Customers/Individual/Wallet/RedeemableServices.vue";
import CustomerFundWalletOption from "@/components/Customers/Individual/Wallet/FundWallet/CustomerFundWalletOption.vue";
import individual from "@/requests/customers/individual";
import CustomerActions from "@/components/Customers/Individual/Actions/CustomerActions";

export default {
  name: "CustomerWallet",
  components: {
    CustomersWalletBanner,
    CustomerFundWalletOption,
    CustomerActions,
    WalletActivity,
    RedeemableServices,
  },
  data() {
    return {
      loading: false,
      showFundWalletOption: false,
      customer: {},
      tab: "activity",
    };
  },
  computed: {
    customerName() {
      return this.customer.name ? this.formatName(this.customer.name) : "";
    },
    customerId() {
      return this.$route.params.id;
    },
  },
  created() {
    this.getCustomer();
    let { t } = this.$route.query;
    this.tab = t ? t : "activity";
  },
  methods: {
    getCustomer() {
      this.loading = true;
      individual
        .get(this.customerId)
        .then((response) => {
          this.customer = response.data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    fetchAllItems() {
      this.$refs.walletActivity.getCustomerActivity({ period: "thisweek" });
      this.getCustomer();
    },
    openFundWallet() {
      this.showFundWalletOption = true;
    },
    updateRouteQuery(tab) {
      const { t } = this.$route.query;

      if (t === this.tab) {
        return false;
      }
      this.$router.push({
        name: "customers.individual.wallet",
        params: { id: this.customerId },
        query: { t: tab.name },
      });
    },
  },
};
</script>
