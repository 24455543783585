<template>
  <div class="service-activity-card font-sm" :class="[type]">
    <el-row type="flex">
      <el-col :span="12">
        <div class="text-bold">Order ID: {{ orderId }}</div></el-col
      >
      <el-col :span="12" class="text-right">
        <div>{{ service.qty }}</div></el-col
      >
    </el-row>
    <el-row class="mt-2">
      <el-col :span="12">
        <div>{{ timeValue }}: {{ service.deliver_to_customer }}</div>
      </el-col>

      <el-col :span="12" class="text-right">
        <router-link
          :to="{
            name: 'orders.order',
            params: { id: orderId },
          }"
          ><span class="font-sm order"> View Order details </span>
        </router-link>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  props: {
    service: { type: Object, required: true },
    orderId: { type: [String, Number], required: true },
    type: { type: [String], required: false },
  },
  computed: {
    timeValue() {
      if (this.type === "meal") return "Delivery date";
      if (this.type === "laundry") return "Pickup date";
      if (this.type === "cleaning") return "Cleaning date";
      if (this.type === "beauty") return "Beauty date";
      return "Delivery date";
    },
  },
};
</script>

<style lang="scss" scoped>
.service-activity-card {
  margin: 10px 0px;
  padding: 18px;
  border: 1px solid;
  border-radius: 8px;
  .order {
    font-weight: 500;
    color: var(--eden-green-primary);
    text-decoration: underline;
  }

  &.beauty {
    background: var(--eden-pink-nonary);
    border-color: var(--eden-pink-quaternary);
  }

  &.cleaning {
    background: var(--eden-orange-nonary);
    border-color: var(--eden-orange-quaternary);
  }

  &.laundry {
    background: var(--eden-purple-nonary);
    border-color: var(--eden-purple-quaternary);
  }

  &.dailymeal,
  &.dailymeals,
  &.meal {
    background: var(--eden-green-nonary);
    border-color: var(--eden-green-quaternary);
  }
}
</style>
