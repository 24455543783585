<template>
  <div class="service-breakdown-card">
    <el-collapse v-model="accordion">
      <el-collapse-item
        :name="service"
        :itemprop="service"
        :class="service.toLowerCase()"
      >
        <template slot="title">
          <div class="service-breakdown-card__title">
            <el-row justify="space-between">
              <el-col :span="24">
                <div class="font-sm service">
                  {{
                    service?.toLowerCase() === "meal"
                      ? "Food"
                      : formatText(service)
                  }}
                </div>
              </el-col>
              <el-col :span="24">
                <div class="text-right text-black">
                  {{
                    accordion.includes(service?.toLowerCase()) ? "Hide" : "View"
                  }}
                  service breakdown
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <div>
                  {{ serviceCount }}
                  {{
                    service?.toLowerCase() === "meal"
                      ? "Food"
                      : formatText(service)
                  }}
                  service{{ serviceCount > 1 ? "s" : "" }}
                  outstanding
                </div>
              </el-col>
            </el-row>
          </div>
        </template>
        <div class="service-breakdown-card__details">
          <template v-if="items.length > 0">
            <div class="mt-2" v-for="(item, index) in items" :key="index">
              <div class="is-flex justify-between">
                <div class="flex items-center">
                  {{ item.pause_start_date }}
                  <img
                    class="timeline-icon"
                    :src="getImage('arrow-right.svg')"
                    alt=""
                  />
                  {{ item.pause_end_date }}
                </div>
                <div class="service-count">
                  {{ item.total_number_of_services }}
                  {{
                    service?.toLowerCase() === "meal"
                      ? "Food"
                      : formatText(service)
                  }}
                  service{{ item.total_number_of_services > 1 ? "s" : "" }}
                </div>
              </div>
            </div>
          </template>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
export default {
  props: {
    item: { type: Object, required: true },
    service: { type: String, required: true },
    items: { type: Array, required: false },
  },
  data() {
    return { accordion: [] };
  },
  computed: {
    serviceCount() {
      return this.items.reduce(
        (acc, item) => acc + item.total_number_of_services,
        0,
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.service-breakdown-card {
  &:not(:last-child) {
    margin-bottom: 18px;
  }
  .timeline-icon {
    height: 8px;
    width: 14px;
  }

  .service-count {
    padding-right: 8px;
  }
  &__title {
    width: 100%;

    > div {
      display: flex;
      justify-content: space-between;

      &:not(:last-child) {
        margin-bottom: 16px;
      }

      .service {
        color: var(--eden-grey-primary);
        font-weight: 500;
        display: flex;
        align-items: center;
      }
    }
  }

  &__details {
    margin-top: 32px;
  }
}
</style>
