<template>
  <eden-container>
    <div class="wallet-banner">
      <div class="value text-bold">{{ formatPrice(balance) }}</div>
      <div class="information font-sm">{{ name }}’s Wallet Balance</div>
    </div>
  </eden-container>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    balance: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.wallet-banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 30px 0px;

  .value {
    margin-bottom: 8px;
    font-size: 2.5rem;
  }
  .information {
    color: var(--eden-grey-tertiary);
  }
}
</style>
