<template>
  <eden-confirm-dialog
    title="Payment link"
    button-text="Share payment link "
    button-type="success"
    :show.sync="shouldShow"
    @confirm="share"
  >
    <div>
      <div class="head-text font-lg">
        {{ paymentLink ? "Share" : "Generate" }} Payment link
      </div>
      <p class="font-md mt-2">
        This link is valid for 24 hours. The link will be made invalid if
        payment isn't made before then.
      </p>
    </div>
    <div class="text-center mt-4">
      <p>Amount to pay</p>
      <span class="text-bold">{{ formatPrice(localAmount) }}</span>
    </div>

    <div class="mt-4 drop-box">
      <i class="eden-icon-loading"></i>
      <input
        class="payment-link-input"
        :class="{'text-primary': !paymentLink}"
        readonly
        ref="paymentLinkInput"
        :value="paymentLink ? paymentLink : 'Generating payment link'"
      />
      <span class="copy-button text-primary" @click="copy"
        ><i class="el-icon-document-copy"></i></span
      >
    </div>
  </eden-confirm-dialog>
</template>
  
  <script>
//   import member from "@/requests/settings/members/member";

export default {
  name: "PostPaidConfirmation",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    amount: {
      type: Number,
      default: 0,
    },
    customer: {
      type: Object,
      default() {
        return {};
      },
    },
    paymentLink: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      localAmount: "",
      link: "",
    };
  },
  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    customerName() {
      return this.formatName(this.customer.name);
    },
    customerId() {
      return this.$route.params.id;
    },
  },
  mounted() {
    this.localAmount = this.amount;
  },
  methods: {
    closeEvent() {
      this.shouldShow = false;
    },
    copy() {
      const linkInput = this.$refs.paymentLinkInput;
      linkInput.select();
      document.execCommand("copy");

      this.$message({
        message: "Payment link copied to clipboard",
        type: "success",
      });
    },
    share() {
      if (this.paymentLink) {
      window.open(this.paymentLink, '_blank');
    } else {
      // Show a message if the payment link is not available
      this.$message({
        message: "Payment link is not available",
        type: "error",
      });
    }
    },
  },
};
</script>
  
<style lang="scss">
.drop-box {
  border: 1px dashed #6ece8a;
  border-radius: 8px;
  padding: 26px 15px;
  text-align: center;
}

.payment-link-input {
  background: none;
  border: none;
  cursor: pointer;
  text-align: center;
  width: 90%; // Adjust width as needed
  outline: none;
  display: inline-block;
  vertical-align: middle;
}

.copy-button {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}
</style>
  