<template>
  <div>
    <el-dialog
      title="Enter Amount"
      :visible.sync="shouldShow"
      @close="closeEvent"
      append-to-body
      width="30%"
    >
      <el-form :model="form" label-position="top" ref="form">
        <el-row type="flex">
          <el-col :span="24">
            <el-form-item label="Amount" prop="amount" :rules="validateField()">
              <el-input
                placeholder="Enter amount to be paid"
                type="text"
                v-number
                v-model.number="form.amount"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24"></el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button plain @click="closeEvent">Cancel</el-button>
        <el-button type="primary" :disabled="!form.amount" @click="generate">Continue</el-button>
      </span>
    </el-dialog>

    <payment-link-confirmation
      v-if="showConfirmation"
      :show.sync="showPaymentLink"
      :amount="amountPayment"
      :paymentLink="paymentLink"
    />
  </div>
</template>

<script>
import wallet from "@/requests/customers/customer/wallet";
import PaymentLinkConfirmation from "@/components/Customers/Corporate/Wallet/FundWallet/PaymentLinkConfirmation";

export default {
  name: "GeneratePaymentLink",
  components: {
    PaymentLinkConfirmation,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    customer: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      linking: false,
      showConfirmation: false,
      showPaymentLink: false,
      form: {
        amount: 0,
      },
      amountPayment: 0,
      paymentLink: ""

    };
  },
  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    customerId() {
      return this.$route.params.id;
    },
  },
  watch: {
    form: {
      handler(form) {
        this.amountPayment = parseFloat(form.amount);
      },
      deep: true,
    },
  },
  methods: {
    closeEvent() {
      this.shouldShow = false;
      this.$refs.form.resetFields();
    },
    submitForm() {
      if (this.$refs.form.validate()) {
        this.shouldShow = false;
        this.showConfirmation = true;
        this.showPaymentLink = true;
      }
    },
    generate() {
      this.showConfirmation = true;
      this.showPaymentLink = true;
      const payload = {
        user_id: this.customerId,
        amount: parseFloat(this.form.amount),
        payment_gateway: "paystack",
      };
      wallet
        .fundWithLink(payload)
        .then((response) => {
          if (response.data.status) {
            this.paymentLink = response.data.data.payment_link
            this.$message.success(response.data.message);
            this.closeEvent();
            
          } else {
            this.loading = false;
            this.$message.error(response.data.message);
          }
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
  },
};
</script>
    
    <style lang="scss" scoped></style>
    