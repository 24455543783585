<template>
  <div class="redeemable-services">
    <service-breakdown-card
      v-for="(item, indx) in services"
      :key="indx"
      :item="item"
      :service="item.service"
      :items="item.redeemable_services"
    />
    <eden-content-empty
      v-if="services.length === 0"
      :text="'No Redeemable services'"
      :size="120"
    />
  </div>
</template>

<script>
import ServiceBreakdownCard from "./ServiceBreakdownCard.vue";
import wallet from "@/requests/customers/customer/wallet";

export default {
  components: {
    ServiceBreakdownCard,
  },
  data() {
    return {
      services: [],
    };
  },
  computed: {
    customerId() {
      return this.$route.params.id;
    },
  },
  methods: {
    getRedeemableServices() {
      wallet.redeemableServices(this.customerId).then((res) => {
        this.services = res.data?.data?.wallet_services;
      });
    },
  },
  created() {
    this.getRedeemableServices();
  },
};
</script>

<style lang="scss" scoped>
.redeemable-services {
  padding: 20px 120px;
}
</style>
